<template>
  <AppContainer id="login" class="my-5">
    <b-row>
      <b-col cols md="8" class="ml-auto mr-auto">
        <center>
          <img
            src="@/assets/logo.png"
            class="rounded-0 text-center"
            width="150"
            alt="Not rounded image"
          />
        </center>
        <h4 class="lead text-center mb-5">Brands Cinemas Control Unit</h4>
        <b-form @submit.prevent="onSubmit" @reset="onReset">
          <b-form-group
            id="input-group-1"
            label="Email address:"
            label-for="email-input"
            description="Only for administrative use."
          >
            <b-form-input
              id="email-input"
              v-model.trim="form.email"
              type="email"
              required
              placeholder="Enter email"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Your Password:" label-for="password-input">
            <b-form-input
              id="password-input"
              v-model="form.password"
              type="password"
              required
              placeholder="Enter password"
            ></b-form-input>
          </b-form-group>

          <b-button v-if="!waiting" type="submit" variant="primary" :disabled="!validated">Login</b-button>
          <b-button v-else type="button" variant="primary" :disabled="validated">
            <eva-icon name="settings-2-outline" animation="pulse" fill="white" class="spinner"></eva-icon>
          </b-button>
        </b-form>
      </b-col>
    </b-row>
  </AppContainer>
</template>

<script>
const AppContainer = () => import("@/core/Components/App/AppContainer.vue");
import { EvaIcon } from "vue-eva-icons";
import { api } from "../../resource";
export default {
  name: "login",
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      waiting: false
    };
  },
  components: {
    AppContainer,
    [EvaIcon.name]: EvaIcon
  },
  methods: {
    onSubmit() {
      this.waiting = true;
      api
        .create("authentication/login", this.form)
        .then(res => {
          this.$store.dispatch("user", res.data);
          this.$store.dispatch("isLoggedIn", true);
          this.waiting = false;
          this.$router.push("/");
        })
        .catch(e => {
          this.waiting = false;
          this.$swal(e.body.status_message, e.body.data, "error");
        });
    },
    onReset() {
      this.form.email = "";
      this.form.password = "";
    }
  },
  computed: {
    validated() {
      return this.form.email.length >= 8 && this.form.password.length >= 6;
    }
  }
};
</script>

<style>
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner {
  animation: spinner 1s linear infinite;
}
</style>
